<script>
  import SvgIcon from '../SvgIcon'

  export default {
    components: {
      SvgIcon
    },
    props: {
      graphic: {
        type: Object,
        required: true
      }
    }
  }
</script>


<template>
  <div>
    <img v-if='graphic.url' class="logo" :src="graphic.url"/>
    <svg-icon v-else-if='graphic.svg' :name='graphic.svg' size='large'></svg-icon>
  </div>
</template>

