<script>
  export default {
    props: ['label'],
    data () {
      return {
        renderTarget: !!(this.$options.components.PortalTarget)
      }
    },

    computed: {
      signInUrl () {
        let teamId = this.$signUpSettings?.team_id
        let path = '/login'
        if (teamId) {
          path += `?team_id=${teamId}`
        }

        return path
      }
    }
  }
</script>


<template>
  <div class='login-button'>
    <div class='login-button__details'>
      <a :href='signInUrl'>{{ label }}</a>
    </div>

    <portal-target v-if='renderTarget' name="after-login-button"></portal-target>
  </div>
</template>


<style lang="scss">
  @import '~@moqomps/style';

  .login-button {
    position: relative;
    float: right;
    height: rem-calc(70);
    white-space: nowrap;
    text-transform: uppercase;
    padding-right: rem-calc(15) !important;

    @include breakpoint(small only) {
      position: absolute;
      right: 0;
    }

    &__details {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }
</style>
