<script>
  import tinycolor from 'tinycolor2'
  import loadingAnim from './partials/loadingAnimationForButton'

  export default {
    components: {
      loadingAnim
    },
    props: {
      is_disabled: {
        type: Boolean,
        required: false,
        default: false
      },
      is_loading: {
        type: Boolean,
        required: false,
        default: false
      },
      /**
       * Text color of the link. Button color if button style.
       * `black, blue, red`
       */
      color: {
        type: String,
        required: false,
        default: 'black'
      },
      /**
       * The app intern target (href) of the link.
       */
      destination: {
        type: String,
        required: false,
        default: null
      },
      href: {
        type: String,
        required: false,
        default: null
      },
      /**
       * The size of the link.
       * `tiny, small, medium, large`
       */
      size: {
        type: String,
        required: false,
        default: 'medium'
      },
      /**
       * The type of the link.
       * `button, simple`
       */
      as: {
        type: String,
        required: false,
        default: 'button'
      }
    },
    computed: {
      hrefDestination () {
        if (this.elementType === 'router-link') return {to: this.destination}
        return {}
      },
      typeClass () {
        if (this.as === 'hollow' || this.as === 'ghost') return 'mq_button mq_button--ghost'
        return 'mq_button'
      },
      sizeClass () {
        return `mq_button--${this.size}`
      },
      customColor () {
        return (this.color.search(/^(#|rgb|hsl)/i) !== -1)
      },
      colorClass () {
        if (this.customColor) return 'mq_button--custom-color'

        return `mq_button--${this.color}`
      },
      customButtonStyle () {
        if (this.customColor && this.as !== 'ghost') {
          return {
            backgroundColor: this.color,
            color: tinycolor(this.color).darken(8).toString()
          }
        }

        return null
      },
      customButtonTextStyle () {
        if (this.customColor) {
          if (this.as === 'ghost') {
            return { color: this.color }
          } else {
            const color = tinycolor(this.color).getLuminance() > 0.65 ? '#000000' : '#FFFFFF'
            return { color }
          }
        }

        return null
      },
      elementType () {
        if (this.href) return 'a'
        if (this.as === 'button' && this.destination) return 'router-link'
        if (this.as === 'button' || !this.destination) return 'button'
        return 'router-link'
      },
      listeners () {
        // make sure to avoid any clicks for disabled buttons
        if (this.is_disabled) {
          return {
            'click': (event) => event.preventDefault()
          }
        }

        // Don't attach listeners to the element if it's a simple link or router link
        if (this.href || this.destination) return null

        return {
          'click': () => this.$emit('click')
        }
      }
    }
  }
</script>


<template>
  <component :is='elementType'
             v-on='listeners'
             :to='destination'
             :href='href'
             :class='[sizeClass, colorClass, typeClass]'
             :style='customButtonStyle'
             :disabled='is_loading || is_disabled'>
    <loading-anim v-if='is_loading' :style='customButtonTextStyle'></loading-anim>
    <span v-else :style='customButtonTextStyle'>
      <slot></slot>
    </span>
  </component>
</template>


<style lang="scss">
  @import '~@moqomps/style';

  @mixin button-shadow($color: 'black') {
    box-shadow: rem-calc(3) rem-calc(3) 0 0 $color;
  }

  @mixin button-hovered-style($color: 'black') {
    box-shadow: rem-calc(2) rem-calc(2) 0 0 $color;
  }

  @mixin button-pressed-style($color: 'black') {
    box-shadow: rem-calc(0) rem-calc(0) 0 0 $color;
  }

  .mq_button{
    display: inline-block;
    text-align: center;
    text-decoration: none;
    font-weight: 900;
    line-height: 1.2;
    width: 100%;
    max-width: 100%;
    margin-bottom: rem-calc(3);
    color: $white;
    cursor: pointer;

    &[disabled] {
      opacity: 0.55;
      cursor: not-allowed;
    }
    &:not([disabled]):hover {
      position: relative;
      top: rem-calc(1);
      left: rem-calc(1);
    }

    &:not([disabled]):active {
      position: relative;
      top: rem-calc(3);
      left: rem-calc(3);
    }

    span {
      line-height: rem-calc(13);
      display: inline-block;
      vertical-align: middle;
    }

    &--fluid{
      font-size: rem-calc(15);
      letter-spacing: normal;
      width: auto;
      padding: rem-calc(12) 0.5em;
    }

    &--tiny{
      font-size: rem-calc(13);
      letter-spacing: normal;
      width: rem-calc(160);
      line-height: rem-calc(30);
      padding: 0 0.5em;
    }

    &--small{
      font-size: rem-calc(15);
      letter-spacing: rem-calc(1.9);
      width: rem-calc(260);
      padding: rem-calc(12) 0.5em;
    }

    &--medium{
      font-size: rem-calc(20);
      letter-spacing: rem-calc(2.7);
      width: rem-calc(580);
      padding: rem-calc(14) 0.5em;

      @include breakpoint(small only){
        font-size: rem-calc(15);
        letter-spacing: rem-calc(1.9);
        width: rem-calc(260);
      }
    }

    &--large{
      font-size: rem-calc(27);
      letter-spacing: rem-calc(3.6);
      width: rem-calc(890);
      padding: rem-calc(16) 0.5em;

      @include breakpoint(small only){
        font-size: rem-calc(15);
        letter-spacing: rem-calc(1.9);
        max-width: rem-calc(260);
      }
    }

    &--blue{
      background-color: $moqo-blue;
      @include button-shadow($moqo-blue-shadow);

      &:hover{
        @include button-hovered-style($moqo-blue-shadow);
        transition: none;
      }

      &:active{
        @include button-pressed-style($moqo-blue-shadow)
      }
    }

    &--red{
      background-color: $moqo-red;
      @include button-shadow($moqo-red-shadow);

      &:hover{
        @include button-hovered-style($moqo-red-shadow);
        transition: none;
      }

      &:active{
        @include button-pressed-style($moqo-red-shadow);
        transition: all 150ms;
      }
    }

    &--white{
      background-color: $white;
      color: $black;
      @include button-shadow($light-gray);

      &:hover{
        @include button-hovered-style($light-gray);
        transition: none;
      }

      &:active{
        @include button-pressed-style($light-gray);
        transition: all 150ms;
      }
    }

    &--custom-color{
      box-shadow: rem-calc(3) rem-calc(3) 0 0;

      &:not([disabled]):hover{
        box-shadow: rem-calc(2) rem-calc(2) 0 0;
        transition: none;
      }

      &:not([disabled]):active{
        box-shadow: rem-calc(0) rem-calc(0) 0 0;
        transition: all 150ms;
      }
    }

    &--ghost {
      background-color: transparent !important;
      border: none !important;
      box-shadow: none !important;

      &.mq_button--red{
        color: $moqo-red;
      }

      &.mq_button--blue{
        color: $moqo-blue;
      }

      &.mq_button--black{
        color: $black;
      }

      &.mq_button--gray{
        color: $dark-gray;
      }
    }

    &:active,
    &:focus {
      outline: none;
    }
  }
</style>
