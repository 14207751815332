<script>
  import SvgIcon from "../SvgIcon"

  export default {
    components: {
      SvgIcon
    },

    props: {
      user: {
        type: Object,
        required: true,
      }
    }
  }
</script>


<template>
  <div class='user-info user'>
    <svg-icon name='person_round' size='35px'></svg-icon>
    <div class='user__details'>
      <div class='user__name'>{{ `${user.first_name} ${user.last_name}` }}</div>
      <div class='user__email'>{{ user.email }}</div>
    </div>
  </div>
</template>


<style lang="scss">
  @import '~@moqomps/style';

  .user {
    position: relative;
    top: 50%;
    width: rem-calc(220);
    transform: translateY(-50%);
    white-space: nowrap;
    padding-right: rem-calc(15) !important;

    &:after {
      position: absolute;
      right: 0;
      border: inset 5px;
      content: "";
      display: block;
      height: 0;
      width: 0;
      margin-top: rem-calc(-4);
      top: rem-calc(30);
      border-color: #000 transparent transparent transparent;
      margin-right: rem-calc(5);

      @include breakpoint(small down) {
        content: none;
      }
    }

    &__icon {
      opacity: 0.5;
      height: 34px;
      width: auto;
    }
    &__details {
      line-height: 1.2em;
      display: inline-block;
      vertical-align: middle;
      margin: 0 0.5em;
    }
    &__name {
      max-width: rem-calc(155);
      font-size: rem-calc(18);
      font-weight: 600;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      // Fix to prevent render bug
      box-shadow: 0px -4px 0px 0px transparent;
    }
    &__email {
      font-size: rem-calc(15);
      text-transform: none;
      line-height: 1.1;
      max-width: rem-calc(155);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      vertical-align: text-top;
    }
  }
</style>
