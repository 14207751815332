<script>
import { currentUser, rolePathPrefix } from '@/current_user'
import MqHeader from "./MqHeader"
import MqFooter from "./MqFooter"
import locales from '@moqomps/locales'

export default {
  i18n: locales,

  components: {
    MqHeader,
    MqFooter
  },

  props: {
    is_loading: {
      type: Boolean,
      default: false
    },
    disable_navigation: {
      type: Boolean,
      required: false,
      default: false
    },
    show_login_button: {
      type: Boolean,
      required: false,
      default: false
    },
    currentUser: {
      type: Object,
      required: false
    }
  },

  data () {
    return {
      headerNotificationAvailable: false
    }
  },

  computed: {
    headerNavi () {
      if (!currentUser()) return

      const headerNavi = []

      headerNavi.push({
        title: this.$t('header.navigation.user_profile'),
        href: `${ rolePathPrefix() }/js_user_profile`
      })

      if(this.$layout.allow_role_selection) {
        headerNavi.push({
          title: this.$t('header.navigation.roles'),
          href: `/roles`
        })
      }

      if(this.$layout.show_board_link) {
        headerNavi.push({
          title: this.$t('header.navigation.board'),
          href: `${ rolePathPrefix() }/board`
        })
      }

      if(!this.$layout.avoid_driver_booking && !this.$layout.free_floating_team) {
        headerNavi.push({
          title: this.$t('header.navigation.booking_browser'),
          href: `${ rolePathPrefix() }/booking_browser/bookings/new`
        })
      }

      headerNavi.push({
        title: this.$t('header.navigation.logout'),
        href: `${ rolePathPrefix() }/logout`
      })

      return headerNavi
    },

    support () {
      return {
        hours: this.$layout.footer.support_hours,
        phone: this.$layout.footer.support_phone,
        mail: this.$layout.footer.support_mail
      }
    },

    mainWrapperClasses () {
      return {
        'notification-available' : this.headerNotificationAvailable,
        'in-app': this.$layout.in_app
      }
    },

    showLoginButton () {
      if (this.headerNavi) return false

      return this.show_login_button
    }
  },

  mounted() {
    // If there are some plain old html flash messages, let's make them beautiful
    let flashes = [window.flash]

    Array.from(document.querySelectorAll('.flashes .flash')).forEach((flashMessage) => {
      flashes.push({message: flashMessage.textContent, color: 'success'})
    })

    flashes.forEach((flash) => {
      if (!flash) return

      const type = this.mapFlashType(flash.color)

      this.$toast.add({
        toastContainerId: 'footer',
        type: type,
        bodyOutputType: 'TrustedHtml',
        body: flash.message,
        timeout: 10000
      })
    })
  },

  methods: {
    mapFlashType (flashType) {
      const typeMap = {
        notice: 'info',
        alert: 'error'
      }
      return typeMap[flashType] || flashType
    }
  }

}
</script>

<template>
  <div>
    <template v-if='is_loading'>
      <mq-loading-overlay :show='true' class='app-loading'></mq-loading-overlay>
    </template>
    <template v-else>
      <mq-header v-if='!$layout.in_app' :user='currentUser' :show_login_button='showLoginButton' :navi_items='headerNavi'></mq-header>

      <div id='main' :class="mainWrapperClasses">
        <slot></slot>
      </div>

      <mq-footer></mq-footer>
  </template>
  </div>
</template>

<style lang="scss">
  @import '~@moqomps/style';
  @import '~@moqomps/styles/foundation_components';

  html, body {
    background-color:  $very-light-gray;
  }

  #main {
    min-height: 100vh;
    padding: rem-calc(150) 0 rem-calc(50);
    background-size: cover;
    background-position: bottom right;
    background-image: url('~@moqomps/assets/images/bg-stripes.svg');

    @include breakpoint(small down) {
      padding: rem-calc(100) 0 0 ;
    }

    &.notification-available {
      padding: rem-calc(230) 0 rem-calc(150) ;

      @include breakpoint(small down) {
        padding: rem-calc(160) 0 rem-calc(150) ;
      }
    }

    &.in-app {
      @include breakpoint(small down) {
        padding-bottom: rem-calc(100);
        padding-top: rem-calc(15);
      }
    }
  }
</style>
