<script>
  import loadingAnim from './partials/loadingAnimationForContainer'

  export default {
    components: {
      loadingAnim
    },
    props: {
      show: {
        type: Boolean,
        required: true
      },
      text: {
        type: String,
        required: false,
        default: null
      },
      color: {
        type: String,
        required: false,
        default: null
      }
    },
    computed: {
      loadingColor () {
        if (this.color) return this.color
        if (this.primaryColor) return this.primaryColor
        return null
      }
    }
  }
</script>


<template>
  <div class='mq-loading-overlay'>
    <slot></slot>

    <div :class="['mq-loading-overlay__container', {show}]">
      <loading-anim :text='text' :color='loadingColor'></loading-anim>
    </div>
  </div>
</template>


<style lang='scss'>
  @import '~@moqomps/style';

  .mq-loading-overlay{
    position: relative;
    
    &.app-loading {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    &__container{
      background-color: rgba($very-light-gray, 0.75);
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;

      &.show {
        display: block;
      }
    }

    &.white{
      background-color: rgba($white, 0.75);
    }

    &.red,
    &.alert{
      background-color: rgba($alert-color, 0.35);
    }

    .loading-text{
      display: inline-block;
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      text-align: center;
      transform: translateY(-50%);
      font-weight: 600;
      z-index: 1;
    }
  }
</style>