<script>
  import { throttle } from 'lodash'
  import tinycolor from 'tinycolor2'

  import HamburgerButton from './partials/hamburgerButton'
  import MobileNavigation from './partials/mobileNavigation'
  import UserInfo from './partials/userInfo'
  import LoginButton from './partials/loginButton'
  import TeamInfo from './partials/teamInfo'
  import locales from '@moqomps/locales'

  export default {
    i18n: locales,

    components: {
      HamburgerButton,
      MobileNavigation,
      UserInfo,
      LoginButton,
      TeamInfo
    },
    props: {
      user: {
        type: Object,
        required: false,
        default: () => null
      },
      navi_items: {
        type: Array,
        required: false,
        default: () => null
      },
      show_login_button: {
        type: Boolean,
        required: false,
        default: false
      },
    },
    data () {
      return {
        showNavi: !(this.$parent.disable_navigation || !this.navi_items),
        openMobileNavi: false,
        pageOnTop: false,
      }
    },
    beforeDestroy () {
      window.removeEventListener('scroll', this.handleScroll)
    },
    mounted () {
      window.addEventListener('scroll', throttle(this.handleScroll, 100))
      setTimeout(this.handleScroll, 200)
      setTimeout(this.controlBodyScrolling, 200)
      this.createLinkStyle()
    },
    computed: {
      notificationsAvailable () {
        return typeof this.$toast === "object"
      }
    },
    methods: {
      createLinkStyle () {
        const color = tinycolor(this.primaryColor)
        const linkColor = color.getLuminance() > 0.65 ? '#000000' : '#FFFFFF'

        const linkStyle = `
          .mq-header .navigation__dropdown li a:hover {
            background-color: ${this.primaryColor};
            color: ${linkColor};
          }
        `
        this.createCustomStyle(linkStyle)
      },
      handleScroll () {
        this.pageOnTop = window.pageYOffset >= 50
      },
      toggleMobileNavi (force = '') {
        if (force === 'force_off') {
          this.openMobileNavi = false
          document.body.classList.remove('mobile-navi-active', this.openMobileNavi)
          return true
        }

        this.openMobileNavi = !this.openMobileNavi
        document.body.classList.toggle('mobile-navi-active', this.openMobileNavi)
      },
      freezeVp (e) {
        if (this.openMobileNavi) {
          e.preventDefault();
        }
      },
      controlBodyScrolling () {
        document.body.addEventListener("touchmove", this.freezeVp, false)
        window.addEventListener("orientationchange", () => {
          if (window.orientation === 90 || window.orientation === 180) this.toggleMobileNavi('force_off')
        });
      }
    }
  }
</script>

<template>
  <div :class="['mq-header', { 'header--with-bg' : pageOnTop}]">
    <div class='row column mq-header__bar'>
      <div class="navigation navigation--desktop show-for-medium">
        <user-info v-if='user' :user='user' :class="{'disable-navigation': !showNavi}"></user-info>

        <div v-if='showNavi' class='navigation__dropdown'>
          <ul>
            <li v-for="item in navi_items">
              <a :href="item.href">{{ item.title }}</a>
            </li>
          </ul>
        </div>
      </div>

      <login-button v-if='show_login_button' :label="$t('header.navigation.login')"></login-button>
      
      <team-info></team-info>

      <hamburger-button v-if='showNavi'
                        class='hide-for-medium'
                        @click.native.prevent='toggleMobileNavi'
                        :closeActive='openMobileNavi'>
      </hamburger-button>
    </div>

    <mobile-navigation v-if='showNavi'
                       :user='user'
                       :navi_items='navi_items'
                       :show='openMobileNavi'>
    </mobile-navigation>

    <toast-container v-if="notificationsAvailable"
                     class='header-toast-container'
                     :toastConfig="{
                        toastContainerId: 'header',
                        preventDuplicates: true,
                        positionClass: ''
                     }">
    </toast-container>
  </div>
</template>

<style lang="scss">
  @use "sass:math";
  @import '~@moqomps/style';

  .mq-header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
    line-height: rem-calc(50);
    padding: rem-calc(40) 0 0;
    background-color: $very-light-gray;
    transition: background-color 350ms, padding 150ms;

    @include breakpoint(small down) {
      padding-top:0;
      padding-bottom:0;
      background-color: $white;
    }

    &.header--with-bg {
      @include breakpoint(medium up) {
        background-color: $white;
        padding: rem-calc(15) 0 0;
      }
    }

    &__bar {
      padding-bottom: rem-calc(20);
      @include breakpoint(small down) {
        position: relative;
        z-index: 2;
        padding-top: rem-calc(5);
        padding-bottom: rem-calc(5);
        background: $white;
      }
    }

    .navigation {
      float: right;

      &--desktop {
        float: right;
        height: rem-calc(70);
      }

      &:hover {
        .navigation__dropdown {
          display: block;
        }
      }

      &__dropdown {
        position: relative;
        padding-top: rem-calc(12);
        display: none;
        z-index: 1;

        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          box-shadow: 2px 2px 0px 1px $light-gray, 0px 0px 1px 0px $light-gray;

          li {
            display: block;
            margin: 0;
            text-align: left;

            a {
              display: block;
              padding: 0.5em 1em;
              font-size: rem-calc(20);;
              background-color: $white;

              &.router-link-exact-active {
                background-color: $moqo-red;
                color: $white;
              }
            }
          }
        }
      }
    }

    .mobile-nav-btn {
      position: absolute;
      right: xy-cell-size(math.div(1, 24));
      top: rem-calc(30);
      z-index: 1;
      transform: translateY(-50%);
      height: rem-calc(22);
      transition: top 50ms;
    }

    .user-info.disable-navigation {
      padding-right: 0 !important;

      &:after {
        display: none;
      }
    }

    .header-toast-container {
      position: static;
    }
  }
</style>