<script>
  import MqLoadingOverlay from "./MqLoadingOverlay" 
  import tinycolor from 'tinycolor2'

  export default {
    components: {
      MqLoadingOverlay
    },
    props: {
      title: {
        type: String,
        required: false,
        default: null
      },
      is_loading: {
        type: Boolean,
        required: false,
        default: false
      },
      loading_text: {
        type: String,
        required: false,
        default: null
      },
      color: {
        type: String,
        required: false,
        default: null
      },
    },
    beforeMount () {
      this.createWrapperStyle()
    },
    methods: {
      createWrapperStyle () {
        const color = tinycolor(this.primaryColor).getLuminance() > 0.65 ? '#000000' : '#FFFFFF'

        const style = `
          .mq_content__header {
            background-color: ${this.primaryColor};
            color: ${color};
          }
          .mq_content__wrapper a:not(.mq_button):not(.mq_link) {
            color: ${this.primaryColor};
          }
          .mq_content__wrapper a:not(.mq_button):not(.mq_link):hover {
            color: ${tinycolor(this.primaryColor).darken(25).toString()};
          }
        `
        this.createCustomStyle(style)
      }
    }
  }
</script>


<template>
  <div class='mq_content__wrapper'>
    <div v-if="title" v-text='title' class='mq_content__header'></div>

    <mq-loading-overlay
        :show='is_loading'
        :text='loading_text'>

      <div class='mq_content__actions'>
        <slot name='actions'></slot>
      </div>

      <div class='mq_content__body'>
        <slot></slot>
      </div>
    </mq-loading-overlay>
  </div>
</template>


<style lang="scss">
  @import '~@moqomps/style';

  .mq_content {
    &__wrapper {
      position: relative;
      background-color: $white;
      box-shadow: 0 rem-calc(20) 0 rem-calc(-10) $light-gray;
    }

    &__actions {
      padding: rem-calc(15) rem-calc(15);
      height: rem-calc(40);
    }

    &__header {
      padding: rem-calc(17) 0;
      text-align: center;
      font-size: rem-calc(23);
      font-weight: 600;
    }

    &__body {
      padding: 0 rem-calc(35) rem-calc(20);
      min-height: rem-calc(500);
    }
  }
</style>
