<script>
  import UserInfo from './userInfo'

  export default {
    components: {
      UserInfo
    },
    props: {
      user: {
        type: Object,
        required: false
      },
      show: {
        type: Boolean,
        required: true,
        default: false
      },
      navi_items: {
        type: Array,
        required: true
      }
    }
  }
</script>


<template>
  <div class="navigation navigation--mobile hide-for-medium" :class="{'show': show}">
      <div class="row">
        <div class="column small-20 small-offset-2">
          <user-info v-if='user' :user='user'></user-info>

          <ul class='navigation navigation--profile'>
            <li v-for="item in navi_items">
              <a :href="item.href">{{ item.title }}</a>
            </li>
          </ul>
      </div>
    </div>
  </div>
</template>


<style lang='scss'>
  @use "sass:math";
  @import '~@moqomps/style';

  .navigation {
    &--mobile {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      min-height: 100vh;
      overflow-y: auto;
      max-height: 100%;
      padding: rem-calc(100) 0 rem-calc(50);
      background-color: $very-light-gray;
      z-index: 1;

      &.show {
        @include breakpoint(medium down){
          display: block;
        }
      }

      a {
        color: $black;
      }

      .user-info {
        transform: none;
        top: 0;
      }

      .navigation_user_container {
        display: none;
        position: absolute;
        right: xy-cell-size(math.div(2, 24));
        left: xy-cell-size(math.div(2, 24));

        &.show {
          display: block;
        }
      }

      ul.navigation {
        float: none;
        width: 100%;
        list-style: none;
        padding: 0;

        &--user {
          margin: 0;

          li {
            float: none;
            width: 100%;
            margin: 0;
            padding: 0;
          }

          a {
            font-size: rem-calc(20);
            font-weight: 400;
            background-color: white;
            padding: 1em;
            display: inline-block;
            width: 100%;
          }
        }

        &--profile {
          float: none;
          width: 100%;

          li {
            float: none;
            width: 100%;
            margin: 0;
            padding: 0.75rem 0;
          }

          a {
            font-size: rem-calc(20);
            font-weight: 400;
          }
        }
      }
    }
  }
</style>
