<script>
  export default {
    props: {
      size: {
        default: '40px'
      },
      color: {
        default: '#FFFFFF'
      },
      duration: {
        default: '2.0s'
      },
      text: {
        type: String,
        default: null
      }
    },
    computed: {
      bounceStyle () {
        return {
          backgroundColor: this.color,
          animationDuration: this.duration
        }
      },
      styles () {
        return {
          width: this.size,
          height: this.size
        }
      }
    }
  }
</script>


<template>
  <div class='loader_wrapper'>
    <div class='spinner_wrapper'>
      <div :style="styles" class="spinner spinner--double-bounce">
        <div class="double-bounce1" :style="bounceStyle"></div>
        <div class="double-bounce2" :style="bounceStyle"></div>
      </div>

      <div class='text' v-if='text' :style='{color}' v-html='text'>L O A D I N G ...</div>
    </div>
  </div>
</template>


<style lang="scss">
  @import '~@moqomps/style';

  .loader_wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .spinner_wrapper {
      position: absolute;
      top: 45%;
      left: 0;
      width: 100%;
      transform: translateY(-50%);
    }

    .spinner {
      position: absolute;
      left: 50%;
      display: inline-block;
      transform: translateX(-50%);

      * {
        line-height: 0;
        box-sizing: border-box;
      }
    }

    .text {
      width: 100%;
      font-size: rem-calc(20);
      text-align: center;
      padding: rem-calc(40) rem-calc(30) rem-calc(5) ;
    }
  }

  .double-bounce1, .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $moqo-blue;
    animation: double-bounce 2.0s ease-in-out infinite;
  }

  .double-bounce2 {
    animation-delay: -1.0s;
  }

  @keyframes double-bounce {
    0%, 100% {
      transform: scale(0.0);
    }
    50% {
      transform: scale(1.0);
    }
  }
</style>