<script>
  import 'whatwg-fetch'

  let cache = new Map()
  const req = require.context("../assets/icons/", true, /^\.\/.*\.svg$/)

  export default {
    props: {
      /**
       * The name of the icon to display without '.svg'
       */
      name: {
        required: true,
        default: "settings",
      },
      /**
       * The fill color of the SVG icon.
       */
      fill: {
        type: String,
        default: null,
      },
      /**
       * The html element name used for the icon.
       */
      type: {
        type: String,
        default: "span",
      },
      /**
       * The size of the icon.
       */
      size: {
        type: String,
        default: "16px",
      },
      /**
       * The top offset of the icon (can be negative).
       */
      offset: {
        type: String,
        default: '0px',
      }
    },
    mounted () {
      this.loadSvg()
    },
    computed:{
      styleObject () {
        return {
          top: this.offset,
          width: this.size,
          height: this.size
        }
      }
    },
    methods: {
      async loadSvg () {
        let currPath = req("./" + this.name + ".svg")
        if (!cache.has(currPath)) {
          try {
            cache.set(currPath, fetch(currPath).then(r => r.text()))
          } catch (e) {
            cache.delete(currPath)
          }
        }
        if (cache.has(currPath)) {
          this.$el.innerHTML = await cache.get(currPath)
          if (this.fill) Array.from(this.$el.getElementsByTagName('path')).forEach((elem) => {elem.style.setProperty('fill', this.fill, 'important')})
          this.$el.children[0].style.width = this.size
          this.$el.children[0].style.height = this.size
        }
      }
    },
    watch: {
      name () {
        this.loadSvg()
      },
      fill () {
        if (this.$el.children.length === 0) { return }
        if (this.fill) this.$el.children[0].style.setProperty('fill', this.fill, 'important')
      },
      size () {
        if (this.$el.children.length === 0) { return }
      }
    }
  }
</script>


<template>
  <component :is='type' class='svg-icon' :style='styleObject'>
  </component>
</template>


<style lang='scss'>
  @import '~@moqomps/style';

  .svg-icon {
    position: relative;
    display: inline-block;
    vertical-align: middle;

    a span {
      position: relative;
      top: rem-calc(3);
    }

    svg {
      max-width: 100%;
      height: auto;
    }

    &.small {
      width: rem-calc(20);
    }

    &.medium {
      width: rem-calc(40);
    }

    &.large {
      width: rem-calc(80);
    }
  }
</style>