<script>
  import QrCode from 'qrcode-svg'

  export default {
    props: {
      content: {
        type: String,
        required: true
      }
    },
    
    computed: {
      qrCodeSvg () {
        if (!this.content) return null

        return new QrCode({
          content: this.content,
          padding: 0,
          width: 200,
          height: 200
        }).svg().replace('height="200"', 'height="200" viewBox="0, 0, 200, 200"')
      }
    }
  }
</script>


<template>
  <div v-if='qrCodeSvg' v-html='qrCodeSvg' class='qr-code-image'></div>
</template>

<style lang='scss'>
  .qr-code-image {
    width: 100%;
    height: 0;
    padding-top: 100%; /* Aspect ratio */
    position: relative;
    
    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
    }
  }
</style>