import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { packEnv, currentLocale } from '@/current_user'
import FoundationPolyfills from '@/lib/foundation-polyfills'

Vue.use(VueI18n)
window.Vue = Vue
Vue.config.ignoredElements = ['money-input', 'auto-complete', 'trix-editor']

export default function(vueOptions, domLoadedCallback) {
  // Build default locales object if none defined
  !vueOptions.locales && (vueOptions.locales = {})
  let locales = processLocales(vueOptions.locales)
  delete vueOptions.locales

  vueOptions.i18n = new VueI18n({
    locale: currentLocale(),
    fallbackLocale: vueOptions.fallbackLocale || 'en', // most complete and used language
    silentFallbackWarn: true,
    messages: locales
  })

  // Build default data store if none defined
  // We need it for application props from ApplicationController
  !vueOptions.data && (vueOptions.data = {})

  Vue.mixin({
    computed: {
      store () { return this.$root.$data }
    }
  })

  require('es6-promise').polyfill()
  document.addEventListener('DOMContentLoaded', () => {
    vueOptions.data.props = packEnv()

    if (domLoadedCallback) { domLoadedCallback() }

    // Make foundation Dropdown Driver, Top Bar Menu functional again
    FoundationPolyfills.install()

    // Render component
    let app = new Vue(vueOptions).$mount('#app')
  })
}

function processLocales(locales) {
  // not a webpackContext
  if (typeof locales.keys === 'undefined') return locales

  const files = locales.keys()
  const values = files.map(locales)
  const rootKeys = Object.keys(values[0])
  let multipleRootKeys = true // e.g index tables
  const result = {}

  if (rootKeys.length === 1) multipleRootKeys = false // e.g users profile, sign up

  for(const i in files) {
    // get locale key from filename extension 
    const languageKey = files[i].replace(/^\.\/(.*)\.\w+$/, '$1')

    result[languageKey] = multipleRootKeys ? values[i] : values[i][rootKeys[0]]
  }

  return result
}
