<script>
  export default {
    props: {
      color_class: {
        type: String,
        required: false,
        default: 'dark'
      },
      closeActive : {
        type: Boolean,
        default: false
      }
    }
  }
</script>


<template>
  <div :class="['mobile-nav-btn', color_class]">
    <button class='hamburger hamburger--squeeze' :class="{'is-active' : closeActive}">
      <span class='hamburger-box'>
        <span class='hamburger-inner'></span>
      </span>
    </button>
  </div>
</template>


<style lang="scss">
  @import '~@moqomps/style';
  @import "~@moqomps/styles/modules/hamburger_button/style";

  .dark {
    .hamburger-inner {
      background-color: $black;

      &:before,
      &:after {
        background-color: $black;
      }
    }
  }

  .light:not(.header--with-bg),
  .is-active {
    .hamburger-inner {
      background-color: $black;

      &:before,
      &:after {
        background-color: $black;
      }
    }
  }
</style>
