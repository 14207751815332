<script>
  import locales from '@moqomps/locales'

  export default {
    i18n: locales,

    computed: {
      iosUrl () {
        return this.$layout.footer.ios_app_url
      },

      androidUrl () {
        return this.$layout.footer.android_app_url
      },

      appBadges () {
        try {
          return {
            appStore: require(`@moqomps/assets/apps/app-store.badge.${this.$i18n.locale}.svg`),
            playStore: require(`@moqomps/assets/apps/play-store.badge.${this.$i18n.locale}.svg`)
          }
        } catch (e) {
          return {
            appStore: require(`@moqomps/assets/apps/app-store.badge.${this.$i18n.fallbackLocale}.svg`),
            playStore: require(`@moqomps/assets/apps/play-store.badge.${this.$i18n.fallbackLocale}.svg`)
          }
        }
      },
      appUrl () {
        if (this.getMobileOperatingSystem() === 'iOS') return this.iosUrl
        return this.androidUrl
      },
      appButtonImage () {
        if (this.getMobileOperatingSystem() === 'iOS') return this.appBadges.appStore
        return this.appBadges.playStore
      },
      unknownDevice () {
        return this.getMobileOperatingSystem() === 'unsupported'
      }
    },
    methods: {
      getMobileOperatingSystem() {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) return 'unsupported'

        if (/android/i.test(userAgent)) return 'android'

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return 'iOS'

        return 'unsupported'
      },
      openAppInfoModal () {
        this.$emit('open-app-info-modal')
      }
    }
  }
</script>


<template>
  <div v-if='appName'>
    <slot name='text'>
      <h5 v-html='appName' class='gutter-bottom'></h5>
      <p class='subline'>{{ $t('footer.mobile_apps.sub_line') }}</p>
    </slot>

    <div v-if='appBadges' class='app_buttons'>
      <div class='row collapse'>
        <template v-if='unknownDevice'>
          <div class='columns small-11'>
            <a :href='iosUrl' target='_blank' rel='nofollow noreferrer noopener'>
              <img :src='appBadges.appStore' alt=''>
            </a>
          </div>
          <div class='columns small-11 small-offset-2'>
            <a :href='androidUrl' target='_blank' rel='nofollow noreferrer noopener'>
              <img :src='appBadges.playStore' alt=''>
            </a>
          </div>
        </template>
        <template v-else>
          <div class='columns text-center'>
            <a :href='appUrl' target='_blank' rel='nofollow noreferrer noopener'>
              <img :src='appButtonImage' alt=''>
            </a>
          </div>
        </template>

      </div>
    </div>
    <p v-if='false && app.magic_link_title' class='gutter-top text-center'>
      <a @click='openAppInfoModal' :style='{color : primaryColor}'>{{ $t('buttons.magic_link') }}</a>
    </p>
  </div>
</template>


<style lang="scss">
  @import '~@moqomps/style';

  .app_buttons{
    max-width: rem-calc(400);
    margin: auto;
  }

  .headline {
    font-size: rem-calc(22);
    margin-top: 0;
    margin-bottom: rem-calc(20);
  }
</style>
