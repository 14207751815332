<script>
  export default {
    props: {
      loading: {
        type: Boolean,
        default: true
      },
      color: {
        type: String,
        default: '#FFFFFF'
      },
      size: {
        type: String,
        default: '10px'
      },
      margin: {
        type: String,
        default: '10px'
      },
      radius: {
        type: String,
        default: '100%'
      }
    },
    data () {
      return {
        spinnerStyle: {
          backgroundColor: this.color,
          borderRadius: this.radius,
          display: 'inline-block',
          animationName: 'v-pulseStretchDelay',
          animationDuration: '750ms',
          animationIterationCount: 'infinite',
          animationTimingFunction: 'cubic-bezier(.2,.68,.18,1.08)',
          animationFillMode: 'both'
        },
        spinnerDelay1: {
          animationDelay: '120ms'
        },
        spinnerDelay2: {
          animationDelay: '240ms'
        },
        spinnerDelay3: {
          animationDelay: '360ms'
        }
      }
    }

  }
</script>


<template>
  <div class="v-spinner" v-show="loading">
    <div class="v-pulse v-pulse1" v-bind:style="[spinnerStyle,spinnerDelay1]">
    </div><div class="v-pulse v-pulse2" v-bind:style="[spinnerStyle,spinnerDelay2]">
  </div><div class="v-pulse v-pulse3" v-bind:style="[spinnerStyle,spinnerDelay3]">
  </div>
  </div>
</template>


<style lang='scss'>
  @import '~@moqomps/style';

  .v-pulse {
    margin: 0 rem-calc(3);
    width: rem-calc(8);
    height: rem-calc(8);

    &:first-child,
    &:last-child {
       margin: 0;
     }
  }

  @keyframes v-pulseStretchDelay
  {
    0%,
    80%
    {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-opacity: 1;
      opacity: 1;
    }
    45%
    {
      -webkit-transform: scale(0.1);
      transform: scale(0.1);
      -webkit-opacity: 0.7;
      opacity: 0.7;
    }
  }
</style>