<script>
  import QrCodeSvg from './partials/qrCodeSvg'
  import locales from '@moqomps/locales'

  export default {
    i18n: locales,

    components: {
      QrCodeSvg
    },

    computed: {
      magicLoginUrl() {
        return this.store?.state?.user?.record?.magic_login_url
      }
    }
  }
</script>


<template>
  <div class='gutter-top' v-if='magicLoginUrl'>
    <slot></slot>
    <p class='gutter-bottom'>{{ $t('footer.magic_login.title') }}</p>
    <qr-code-svg :content='magicLoginUrl'></qr-code-svg>
  </div>
</template>