<script>

export default {
  computed: {
    image () {
      return this.$layout.header.image
    },

    color () {
      return this.$layout.color || this.moqoBlue
    },

    title () {
      return this.$signUpSettings ? this.$signUpSettings.name : this.$layout.header.title
    },

    logo () {
      if (!this.image) { return }
      return this.image.medium_url
    },

    teamStyle () {
      if (!this.color) { return }
      return {
        color: this.color
      }
    }
  }
}
</script>


<template>
  <div class='team'>
    <img v-if='logo' class='team__logo' :src='logo' alt=''/>
    <div class='team__details'>
      <div v-if='title' class='team__name' :style='teamStyle'>{{title}}</div>
    </div>
  </div>
</template>


<style lang="scss">
  @import '~@moqomps/style';

  .team {
    height: rem-calc(70);
    padding-right: rem-calc(20);
    overflow: hidden;

    @include breakpoint(small only) {
      padding-right: rem-calc(30);
    }

    &__logo {
      height: 100%;
      width: auto;
      float: left;
      margin-right: rem-calc(20);

      @include breakpoint(small only) {
        margin-right: rem-calc(15);
      }
    }

    &__details {
      top: 50%;
      position: relative;
      transform: translateY(-50%);
      margin-left: rem-calc(20);
    }

    &__name {
      margin: 0;
      font-size: rem-calc(27);
      line-height: rem-calc(25);

      @include breakpoint(mediumlarge only) {
        font-size: rem-calc(25);
        line-height: rem-calc(25);
      }

      @include breakpoint(medium only) {
        font-size: rem-calc(22);
        line-height: rem-calc(22);
      }

      @include breakpoint(small only) {
        font-size: rem-calc(14);
        line-height: rem-calc(14);
      }
    }
  }
</style>
