export default {
  install() {
    let header = document.getElementById('header')
    let topbar = document.querySelector('nav.top-bar')
    let section = document.querySelector('section.top-bar-section')
    if (header && topbar && section) {
      header.classList.add('no-js')

      let dropdowns = document.querySelectorAll('li.has-dropdown > ul.dropdown')
      for (let i = 0; i < dropdowns.length; ++i) {
        let li = document.createElement('li')
        li.classList.add('title', 'back', 'js-generated')
        li.innerHTML = `<h5 class="toggle-back"><a href="#">${topbar.getAttribute('data-back-text')}</a></h5>`
        dropdowns[i].insertBefore(li, dropdowns[i].firstChild)
      }

      header.addEventListener('click', (e) => {
        let parent = e.target.parentNode

        if (parent.classList.contains('toggle-topbar')) {
          topbar.classList.toggle('expanded')
          resetSection()
        }

        if (parent.classList.contains('has-dropdown')) {
          parent.classList.add('moved')
          section.style.left = '-100%'
        }

        if (parent.classList.contains('toggle-back')) {
          resetSection()
        }
      })

      function resetSection() {
        section.style.left = ''
        let movedDropdown = section.querySelector('li.moved')
        if (movedDropdown) {
          movedDropdown.classList.remove('moved')
        }
      }
    }
  }
}
