<script>
  import { rolePathPrefix } from '@/current_user'
  import MobileApps from './partials/mobileApps'
  import MagicLoginQrCode from './MqMagicLoginQrCode'
  import BrandingElement from './partials/brandingElement'
  import locales from '@moqomps/locales'

  const moqoLogo = require("@moqomps/assets/images/MOQO_logo.svg")

  export default {
    i18n: locales,

    components: {
      MobileApps,
      MagicLoginQrCode,
      BrandingElement
    },

    computed: {
      navItems () {
        return [
          { name: this.$t('footer.navigation.imprint'), url: `${ rolePathPrefix() }/redirect/site-notice${this.urlParams()}` },
          { name: this.$t('footer.navigation.privacy'), url: `${ rolePathPrefix() }/redirect/privacy-policy${this.urlParams()}` },
          { name: this.$t('footer.navigation.cookies'), url: `${ rolePathPrefix() }/redirect/cookies${this.urlParams()}` }
        ]
      },

      notificationsAvailable () {
        return typeof this.$toast === "object"
      },

      poweredByMoqoImage () {
        return this.$layout.enabled_by_moqo ? `enabled_by_moqo` : null
      },

      providerAppImage () {
        return this.$layout.footer.app_icon?.medium.url || moqoLogo
      },

      branding () {
        if (this.poweredByMoqoImage || this.providerAppImage) {
          return {
            svg: this.poweredByMoqoImage,
            url: this.providerAppImage
          }
        }

        return null
      },

      inApp () {
        return !!this.$layout.in_app
      },

      magicLoginUrl () {
        return this.$parent.currentUser?.magic_login_url
      },

      helpCenterUrl () {
        return `${ rolePathPrefix() }/contact${this.urlParams()}`
      }
    },
    
    methods: {
      urlParams () {
        if (rolePathPrefix()) return ''
        if (this.$layout.team_id) return `?team_id=${this.$layout.team_id}`
        return ''
      }
    }
  }
</script>

<template>
  <footer :class="['mq-footer', {'in-app': inApp}]">
    <div class="row">
      <div class="column small-offset-1 small-22">

        <div class="row">
          <div class="column mediumlarge-8 medium-4 footer-cell logo-cell">
            <branding-element v-if='branding' :graphic='branding'></branding-element>
          </div>

          <div v-if='!inApp' class="column footer-cell  mediumlarge-8 mediumlarge-push-8 medium-10 medium-push-10">
            <div class="column medium-22 medium-offset-1 large-18">
              <mobile-apps />

              <template v-if='magicLoginUrl'>
                <div class='hide-for-small-only gutter-top'>
                  <magic-login-qr-code/>
                </div>

                <div class='show-for-small-only gutter-top large-gutter-bottom'>
                  <hr>
                  <h3 v-html="$t('footer.magic_login_link.title')" class='small-gutter-top gutter-bottom'></h3>
                  <p class='gutter-bottom' v-html="$t('footer.magic_login_link.content')" ></p>
                  <mq-button as='link' class='magic_login show-for-small-only float-center' :color='primaryColor' size='small' :href='magicLoginUrl'>{{ $t('buttons.magic_link') }}</mq-button>
                </div>
              </template>
            </div>
          </div>

          <div v-if='!inApp' class="column footer-cell  mediumlarge-8 mediumlarge-pull-8 medium-10 medium-pull-10">
            <div class="column medium-22 medium-offset-1 large-20 large-offset-3">

              <h5 class='gutter-bottom'>{{ $t('footer.support.title') }}</h5>
              <div class="support-info">
                <p class="bold medium">{{ $t('footer.support.info') }}</p>
                <p>
                  <a :style='{color: primaryColor}' :href='helpCenterUrl'>{{ $t('buttons.help_center_link') }} →</a>
                </p>
              </div>

            </div>
          </div>
        </div>

        <div v-if='!inApp' class="row">
          <div class="column text-center">
            <ul class="navigation primary">
              <li v-for='item in navItems'>
                <a target="_blank" :href="item.url">{{ item.name }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <toast-container v-if="notificationsAvailable" class='toast-footer-container' :toastConfig="{ toastContainerId: 'footer', positionClass: 'vot-bottom-right', animation: 'ease-out-right' }"></toast-container>
  </footer>
</template>

<style lang="scss">
  @import '~@moqomps/style';

  footer.mq-footer {
    padding: rem-calc(40) 0 rem-calc(40);
    background-color: $white;

    .footer-cell {
      margin-bottom: rem-calc(40);
    }

    svg {
      width: 100%;
      height: auto;
    }
    .svg-icon {
      display: block;
      max-width: rem-calc(140);

      @include breakpoint(small only) {
        max-width: rem-calc(125);
      }
    }

    @include breakpoint(medium only){
      .svg-icon {
        width: 80%;
      }
    }

    @include breakpoint(small only){
      padding: rem-calc(20) 0 rem-calc(20);
      .logo-wrapper {
        text-align: center;
      }
      .svg-icon {
        margin: auto;
        width: 40%;
      }
    }

    p {
      font-size: rem-calc(15);
      line-height: rem-calc(20);
      margin-top: rem-calc(10);
      margin-bottom: rem-calc(20);
      &.bold {
        font-weight: bold;
      }
    }

    .logo {
      width: 100%;
      height: auto;
      max-width: rem-calc(140);
      margin-bottom: rem-calc(80);

      @include breakpoint(small only){
        max-width: rem-calc(125);
        margin-bottom: rem-calc(50);
      }
    }

    ul.navigation {
      list-style: none;
      text-align: center;
      width: auto;
      margin: auto;
      margin-top: rem-calc(60);
      padding: 0;

      li {
        display: inline-block;
        float: none;
        padding: 0 1em;
        margin: 0;

        @include breakpoint(medium down) {
          padding: 0 0.5em;
        }
      }

      a, router-link {
        padding: 0;
        text-transform: none;
        text-decoration: none;
        font-size: rem-calc(12);
        font-weight: 400;
        color: $black;
      }
    }

    ul.primary.navigation {
      text-align: center;
    }

    .logo-cell {
      @include breakpoint(small only){
        text-align: center;
      }
    }

    .toast-footer-container {
      max-width: 37rem;
    }
  }
</style>
