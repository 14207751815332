export const rolePathPrefix = function() {
  let prefix = rolePrefix() || ''

  if (currentRouteRole() && currentEntityId()) {
    return `${prefix}/${currentRouteRole()}/${currentEntityId()}`
  }

  return prefix
}

export const getMetaContent = function(name) {
    let meta = document.head.querySelector(`meta[name=${name}]`)
    return meta && meta.content
}

export const currentUser = function() {
    return getMetaContent('current-user')
}

export const rolePrefix = function() {
    return getMetaContent('role-prefix')
}

export const currentRole = function() {
    return getMetaContent('current-role')
}

export const currentRouteRole = function() {
    return getMetaContent('current-route-role')
}

export const currentEntityId = function() {
    return getMetaContent('current-entity')
}

export const currentLocale = function() {
  return document.documentElement.lang
}

export const packEnv = function() {
  let node = document.getElementById('main-content')
  if (!node) { return {} }
  let props = JSON.parse(node.getAttribute('data'))
  props.current_locale = currentLocale()

  return props
}