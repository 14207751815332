import { render, staticRenderFns } from "./mobileApps.vue?vue&type=template&id=0a99ac0e"
import script from "./mobileApps.vue?vue&type=script&lang=js"
export * from "./mobileApps.vue?vue&type=script&lang=js"
import style0 from "./mobileApps.vue?vue&type=style&index=0&id=0a99ac0e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports