export default {
  computed: {
    moqoBlue () {
      return '#7ADEF4' // moqo blue
    },

    moqoRed () {
      return '#FF655C' // moqo red
    },

    primaryColor () {
      return this.$layout.primary_color || this.moqoBlue
    },

    secondaryColor () {
      return this.$layout.secondary_color || this.moqoRed
    },

    appName () {
      return this.$layout.footer.app_name
    }
  },

  methods: {
    createCustomStyle (rules) {
      let head = document.head
      let style = document.createElement('style')

      style.type = 'text/css'
      if (style.styleSheet) {
        style.styleSheet.cssText = rules
      } else {
        style.appendChild(document.createTextNode(rules))
      }

      head.appendChild(style)
    },

    escapeHTML (s) {
      if (!s) { return s }
      return s.replace(/[&"<>]/g, function (c) {
        return {'&': "&amp;", '"': "&quot;", '<': "&lt;", '>': "&gt;"
        }[c];
      });
    },

    showErrorToast (error) {
      if (!error) return

      const error_message = error.response?.data?.error_message || error.response?.data?.error || error

      if (error_message && typeof error_message == 'string') {
        this.$toast.add({ toastContainerId: 'footer', type: 'error', body: error_message, timeout: 5000 })
      }
    }
  }
}